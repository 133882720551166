'use strict';
window.jQuery = window.$ = require('jquery');
// ======================================================
// Requirements
// ======================================================

const $ = require('jquery');
const _ = require('underscore');
const fancybox = require('fancybox')($);
const imagesLoaded = require('imagesloaded');
const slick = require('slick-carousel');
const parsley = require('parsleyjs');
const alertify = require('alertify.js');
const jQBridget = require('jquery-bridget');
const Masonry = require('masonry-layout');
const matchHeight = require('jquery-match-height');
const selectOrDie = require('./selectordie');

//==================================================================
// Slider
//==================================================================

let $slider = $('.js-slider').slick({
    infinite      : true,
    arrows        : false,
    adaptiveHeight: true,
    slidesToShow  : 1,
    responsive    : [
        {
            breakpoint: 1024,
            settings  : {
                slidesToShow  : 1,
                slidesToScroll: 1,
                infinite      : true,
                dots          : true
            }
        }
    ]
});

$('.js-next').on('click', function (e) {
    e.preventDefault();
    $slider.slick('slickNext');
});

$('.js-prev').on('click', function (e) {
    e.preventDefault();
    $slider.slick('slickPrev');
});

// ==================================================================
// Navburger
//==================================================================

let $navburger = $('.navburger');

$navburger.on('click', function (e) {
    e.preventDefault();
    $('body').toggleClass('mobile-nav');
});

//==================================================================
// Contact Tabs
//==================================================================

let $tab = $('.tabs-controls a');
let $tabContent = $('.tab-content__item');

$tab.on('click', function (e) {
    e.preventDefault();
    let $this = $(this);
    let index = $this.index();
    $('.active').removeClass('active');
    $this.addClass('active');
    $tabContent.eq(index).addClass('active')
});


//==================================================================
// Contact Form
//==================================================================

$('select').selectOrDie();

const $form = $('#QuoteForm_QuoteForm');

if ($form.length > 0) {
    $form.parsley({
        classHandler: function (el) {
            return el.$element.closest('.input-wrap');
        }
    }).on('form:submit', function () {
      $form.addClass('busy');
        $.ajax({
                url : $form.attr('action'),
                data: $form.serialize()
            })
            .done(()=> {
                alertify.alert("Form sent successfully");
                setTimeout(()=> {
                    $form[0].reset();
                }, 300);
            })
            .fail(()=> {
                alertify.alert("Message could not be sent");
            })
            .always(()=> {
                setTimeout(()=> {
                  $form.removeClass('busy');
                }, 300);
            });
        return false;
    });
}

//==================================================================
// Transactions
//==================================================================

$('.js-change li').on('click', function (e) {
    e.preventDefault();
    let index = $(this).index();
    $('li').removeClass('active');
    $(this).addClass('active');
    $('.transaction_item').removeClass('active').eq(index).addClass('active');
});


//==================================================================
// About
//==================================================================

$('.js-about').on('click', function (e) {
    e.preventDefault();
    let index = $(this).index();
    $('.js-about').removeClass('active');
    $(this).addClass('active');
    $('.about__item').removeClass('active').eq(index).addClass('active');
});
